import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import { UilTrashAlt, UilEditAlt } from '@iconscout/react-unicons';

import TinymceEditor from 'components/base/TinymceEditor';
import axios from 'axios'; // Add axios for making HTTP requests
import { toast } from 'react-toastify';
import QuizModal from 'components/modals/QuizModal';
import QuizUpdateModal from 'components/modals/QuizzUpdateModal';

const CreateCourses = () => {
  const courseId = useParams();
  const [openCourse, setOpenCourse] = useState<number | null>(null);
  const [openChapter, setOpenChapter] = useState<number | null>(null);
  const [openUnity, setOpenUnity] = useState<number | null>(null);
  const [newUnitId, setNewUnitId] = useState<number | null>(null);
  const [showUnitModal, setShowUnitModal] = useState(false);
  const [showUnitUpdateModal, setShowUnitUpdateModal] = useState(false);
  const [showChapterModal, setShowChapterModal] = useState(false);
  const [showChapterUpdateModal, setShowChapterUpdateModal] = useState(false);
  const [newUnitName, setnewUnitName] = useState('');
  const [updateUnitName, setUpdateUnitName] = useState('');
  const [newChapterName, setnewChapterName] = useState('');
  const [newChapterDescription, setnewChapterDescription] = useState('');
  // const [newChapterObjective, setnewChapterObjective] = useState('');
  const [updateChapterName, setUpdateChapterName] = useState('');
  const [updateChapterDescription, setUpdateChapterDescription] = useState('');
  // const [updateChapterObjective, setUpdateChapterObjective] = useState('');
  const [courses, setCourses] = useState({
    id: 0,
    name: '',
    duration: '',
    description: '',
    objective: '',
    sections: [
      {
        id: 0,
        name: '',
        position: '',
        lessons: [
          {
            id: 0,
            name: '',
            content: '',
            objective: '',
            type: '',
            questions: [
              {
                id: 0,
                type: '',
                question: '',
                options: [],
                correctAnswerIndex: null,
                correctAnswerIndices: [],
                correctAnswer: null
              }
            ]
          }
        ]
      }
    ]
  });

  const token = localStorage.bearerToken;

  const headers = {
    Authorization: `Bearer ${token}`
  };

  useEffect(() => {
    async function fetchCourses() {
      try {
        const response = await axios.get(
          `https://apis.blue-tourism.eu/api/v1/course/${courseId.id}`,
          { headers }
        );
        console.log('courses :', response.data);
        setCourses(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchCourses();
  }, [courseId.id]);

  // const handleEditCourse = () => {
  //   const updatedCourses = { ...courses };
  //   setCourses(updatedCourses);
  // };
  const handleAddUnit = () => {
    setShowUnitModal(true);
  };

  const handleAddChapter = (unitId: number) => {
    console.log(unitId);
    setShowChapterModal(true);
    setNewUnitId(unitId);
  };

  const handleUnitChange = (
    unitIndex: number,
    event: { target: { name: string; value: string } }
  ) => {
    const updatedUnits = [...courses.sections];
    const updatedUnit = {
      ...updatedUnits[unitIndex],
      [event.target.name]: event.target.value
    };
    updatedUnits[unitIndex] = updatedUnit;
    setCourses({ ...courses, sections: updatedUnits });
  };

  // const handleChapterChange = (
  //   unitIndex: number,
  //   chapterIndex: number,
  //   event: { target: { name: string; value: string } }
  // ) => {
  //   const updatedUnits = [...courses.sections];
  //   const updatedUnit = { ...updatedUnits[unitIndex] };
  //   const updatedChapters = [...updatedUnit.lessons];
  //   const updatedChapter = {
  //     ...updatedChapters[chapterIndex],
  //     [event.target.name]: event.target.value
  //   };
  //   updatedChapters[chapterIndex] = updatedChapter;
  //   updatedUnit.lessons = updatedChapters;
  //   updatedUnits[unitIndex] = updatedUnit;
  //   setCourses({ ...courses, sections: updatedUnits });
  // };

  const handleRemoveUnit = async (unitIndex: number) => {
    // const courseId = courses.id; // assume you have an `id` property on each course object
    const unitId = courses.sections[unitIndex].id; // assume you have an `id` property on each unit object

    try {
      const response = await axios.delete(
        `https://apis.blue-tourism.eu/api/v1/section/${unitId}`,
        { headers }
      );
      toast.success('Unité supprimée avec succès');
      console.log('deleteData :', response);
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors de la suppression de l'unité");
    }
    setCourses({
      ...courses,
      sections: courses.sections.filter((_, i) => i !== unitIndex)
    });
  };

  const handleRemoveChapter = async (
    unitIndex: number,
    chapterIndex: number
  ) => {
    const chapterId = courses.sections[unitIndex].lessons[chapterIndex].id; // assume you have an `id` property on each chapter object
    console.log(chapterId);
    try {
      const response = await axios.delete(
        `https://apis.blue-tourism.eu/api/v1/lesson/${chapterId}`,
        { headers }
      );
      toast.success('Chapitre supprimé avec succès');
      console.log('deleteData :', response);
    } catch (error) {
      console.error(error);
      toast.error('Erreur lors de la suppression du chapitre');
    }
    setCourses({
      ...courses,
      sections: courses.sections.map((section, sectionIndex) => {
        if (sectionIndex === unitIndex) {
          return {
            ...section,
            lessons: section.lessons.filter((_, i) => i !== chapterIndex)
          };
        }
        return section;
      })
    });
  };

  const handleRemoveQuizz = async (
    questionIndex: number,
    unitIndex: number,
    lessonIndex: number
  ) => {
    const QuestionId =
      courses.sections[unitIndex].lessons[lessonIndex].questions[questionIndex]
        .id;

    console.log(QuestionId);

    try {
      const response = await axios.delete(
        `https://apis.blue-tourism.eu/api/v1/quizz/question/${QuestionId}`,
        { headers }
      );
      toast.success('Question supprimée avec succès');
      console.log('deleteData :', response);
      // Remove the question from the state
      setCourses({
        ...courses,
        sections: courses.sections.map((section, sectionIndex) => {
          if (sectionIndex === unitIndex) {
            return {
              ...section,
              lessons: section.lessons.map((lesson, lessonIdx) => {
                if (lessonIdx === lessonIndex) {
                  return {
                    ...lesson,
                    questions: lesson.questions.filter(
                      (_, questionIdx) => questionIdx !== questionIndex
                    )
                  };
                }
                return lesson;
              })
            };
          }
          return section;
        })
      });
    } catch (error) {
      console.error(error);
      toast.error('Erreur lors de la suppression du chapitre');
    }
  };

  const handleEditUnit = (unitIndex: number) => {
    const unitId = courses.sections[unitIndex].id;
    // console.log(unitId);
    setShowUnitUpdateModal(true);
    // console.log(courses.sections[unitIndex].name);
    setUpdateUnitName(courses.sections[unitIndex].name);
    setNewUnitId(unitId);
  };

  const validAddUnit = async () => {
    // const courseId = courses.id; // assume you have an `id` property on each course object
    const newUnit = {
      name: newUnitName,
      courseId: courseId.id
    };

    try {
      const response = await axios.post(
        'https://apis.blue-tourism.eu/api/v1/section',
        newUnit,
        { headers }
      );
      const newUnitId = response.data.id;
      console.log(newUnitId);
      toast.success('Unité créée avec succès');
      console.log('createData :', response);
      setShowUnitModal(false);
      window.location.reload();
      return newUnitId;
    } catch (error) {
      console.error(error);
      toast.error('Error de création lors de la section');
    }
  };

  const handleEditChapter = (unitIndex: number, chapterIndex: number) => {
    setShowChapterUpdateModal(true);
    setUpdateChapterName(
      courses.sections[unitIndex].lessons[chapterIndex].name
    );
    setUpdateChapterDescription(
      courses.sections[unitIndex].lessons[chapterIndex].content
    );
    // setUpdateChapterObjective(
    //   courses.sections[unitIndex].lessons[chapterIndex].objective
    // );
  };

  const validAddChapter = async () => {
    // const unitId = localStorage.unitId;
    console.log(newUnitId);
    const newChapter = {
      name: newChapterName,
      content: newChapterDescription,
      sectionId: newUnitId
    };

    try {
      const response = await axios.post(
        `https://apis.blue-tourism.eu/api/v1/lesson`,
        newChapter,
        { headers }
      );
      toast.success('Chapitre créer avec  success');
      console.log('lesson created:', response);
      setShowChapterModal(false);
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error('Error de création lors du chapitre');
    }
  };

  // const handleEditorChange = (
  //   unitIndex: number,
  //   chapterIndex: number,
  //   description: string
  // ) => {
  //   setCourses(prevCourses => {
  //     const updatedCourses = { ...prevCourses };
  //     const updatedUnit = updatedCourses.sections[unitIndex];
  //     const updatedChapter = updatedUnit.lessons[chapterIndex];
  //     updatedChapter.description = description;
  //     return updatedCourses;
  //   });
  // };

  // Popup d'ajout de quizs
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = (unitId: number) => {
    setShowPopup(!showPopup);
    setNewUnitId(unitId); // Set the modalUnitId state
  };
  const toogleClosePopup = () => {
    setShowPopup(!showPopup);
  };

  /// Popup de mise à jour de quizz
  const [quizzToUpdate, setQuizzToUpdate] = useState<{
    id: number;
    type: string;
    question: string;
    options: string[];
    correctAnswerIndex: number | null;
    correctAnswerIndices: number[];
    correctAnswer: boolean | null;
  } | null>(null);

  const [showUpdatePopup, setShowUpdatePopup] = useState(false);

  // const toggleUpdatePopup = (quizz: {
  //   id: number;
  //   type: string;
  //   question: string;
  //   options: string[];
  //   correctAnswerIndex: number | null;
  //   correctAnswerIndices: number[];
  //   correctAnswer: boolean | null;
  // }) => {
  //   setShowUpdatePopup(!showUpdatePopup);
  //   setQuizzToUpdate(quizz); // Set the modalUnitId state
  // };

  const toogleCloseUpdatePopup = () => {
    setShowUpdatePopup(!showUpdatePopup);
    setQuizzToUpdate(null);
  };

  const handleEditorChangeDescription = (description: string) => {
    setnewChapterDescription(description);
  };

  const handleEditorChangeUpdateDescription = (description: string) => {
    setUpdateChapterDescription(description);
  };

  // const handleEditorChangeObjective = (description: string) => {
  //   setnewChapterObjective(description);
  // };

  const validEditUnit = async (unitId: number) => {
    const updatedUnit = {
      name: updateUnitName
    };
    console.log(unitId);
    try {
      const response = await axios.patch(
        `https://apis.blue-tourism.eu/api/v1/section/${unitId}`,
        updatedUnit,
        { headers }
      );
      toast.success('Unité mise à jour avec succès');
      console.log('updateData :', response);
      setShowUnitModal(false);
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors de la mise à jour de l'unité");
    }
  };

  const validEditChapter = async (unitIndex: number, chapterIndex: number) => {
    const updatedChapter = {
      name: updateChapterName,
      content: updateChapterDescription
      // objective: updateChapterObjective
    };
    const chapterId = courses.sections[unitIndex].lessons[chapterIndex].id;
    console.log(chapterId);
    try {
      const response = await axios.patch(
        `https://apis.blue-tourism.eu/api/v1/lesson/${chapterId}`,
        updatedChapter,
        { headers }
      );
      toast.success('Chapitre mise à jour avec succès');
      console.log('updateData :', response);
      setShowChapterModal(false);
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors de la mise à jour de l'unité");
    }
  };

  // const handleEditQuizz = () => {
  //   setShowQuizzModal(true);
  //   // setnewUnitName(courses.sections[quizzIndex].name);
  // };

  const handleUpdateQuizz = async (updatedQuizz: {
    id: number;
    type: string;
    question: string;
    options: string[];
    correctAnswerIndex: number | null;
    correctAnswerIndices: number[];
    correctAnswer: boolean | null;
  }) => {
    try {
      const response = await fetch(
        `https://apis.blue-tourism.eu/api/v1/quizz/question/${updatedQuizz.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            type: updatedQuizz.type,
            question: updatedQuizz.question,
            options: updatedQuizz.options,
            correctAnswerIndex: updatedQuizz.correctAnswerIndex,
            correctAnswerIndices: updatedQuizz.correctAnswerIndices,
            correctAnswer: updatedQuizz.correctAnswer
          })
        }
      );

      if (response.ok) {
        toast.success('Quizz mise à jour avec succès');
        console.log('Activity updated successfully', response);
        // Mettre à jour l'état ou effectuer d'autres actions après la mise à jour
      } else {
        toast.error('Error de mise à jour');
        console.error('Erreur de mise à jour', response.statusText);
      }
    } catch (error) {
      toast.error('Error de mise à jour');
      console.error('Erreur de mise à jour', error);
    } finally {
      setShowUpdatePopup(!showUpdatePopup);
      window.location.reload();
    }
  };

  return (
    <div
      className="card theme-wizard mb-5"
      data-theme-wizard="data-theme-wizard"
    >
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="px-6 py-4">
          <div className="tab-content">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item border-top border-300">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    onClick={() => setOpenChapter(openChapter === 1 ? null : 1)}
                  >
                    <span className="space-x-2">
                      {courses.name}
                      <button
                        className="btn btn-primary"
                        onClick={() => handleAddUnit()}
                      >
                        Ajouter une unité
                      </button>
                    </span>
                  </button>
                </h2>
                {openChapter === 1 && (
                  <div
                    className="accordion-collapse collapse show"
                    id="collapseOne"
                    aria-labelledby="headingOne"
                  >
                    <div className="accordion-body pt-0">
                      {courses.sections.map((unit, unitIndex) => (
                        <>
                          <div
                            key={unitIndex}
                            className="accordion"
                            id="accordionUnit"
                          >
                            <div className="accordion-item border-top border-300">
                              <h2 className="accordion-header" id="headingUnit">
                                <button
                                  className="accordion-button"
                                  type="button"
                                  onClick={() =>
                                    setOpenUnity(
                                      openUnity === unitIndex + 1
                                        ? null
                                        : unitIndex + 1
                                    )
                                  }
                                >
                                  <span>
                                    Unité {unitIndex + 1}
                                    <button
                                      className="btn btn-sm btn-danger ml-2"
                                      onClick={() =>
                                        handleRemoveUnit(unitIndex)
                                      }
                                    >
                                      <UilTrashAlt size={8} />
                                    </button>
                                    <button
                                      className="btn btn-sm btn-primary ml-2 ms-2"
                                      onClick={() => handleEditUnit(unitIndex)}
                                    >
                                      <UilEditAlt size={8} />
                                    </button>
                                  </span>
                                </button>
                              </h2>
                              <Modal
                                show={showUnitUpdateModal}
                                onHide={() => setShowUnitUpdateModal(false)}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>
                                    Mettre à jour l'unité
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <form>
                                    <div className="form-group">
                                      <label>Nom de l'unité</label>
                                      <input
                                        type="text"
                                        value={updateUnitName}
                                        onChange={e =>
                                          setUpdateUnitName(e.target.value)
                                        }
                                        className="form-control"
                                      />
                                    </div>
                                  </form>
                                </Modal.Body>
                                <Modal.Footer>
                                  <button
                                    className="btn btn-secondary"
                                    onClick={() =>
                                      setShowUnitUpdateModal(false)
                                    }
                                  >
                                    Annuler
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => validEditUnit(unit.id)}
                                  >
                                    Mettre à jour l'unité
                                  </button>
                                </Modal.Footer>
                              </Modal>
                              {openUnity === unitIndex + 1 && (
                                <div
                                  className="accordion-collapse collapse show"
                                  id={`collapseUnit-${unitIndex}`}
                                  aria-labelledby="headingUnit"
                                  data-bs-parent="#accordionUnit"
                                >
                                  <div className="accordion-body pt-0">
                                    <form className="row g-3 needs-validation">
                                      <div className="col mb-2">
                                        <label
                                          className="form-label"
                                          htmlFor={`unit-name-${unitIndex}`}
                                        >
                                          Titre de l'unité
                                        </label>
                                        <input
                                          className="form-control"
                                          type="text"
                                          name="name"
                                          placeholder="Nom de l'unité"
                                          // id={`unit-name-${unitIndex}`}
                                          value={unit.name}
                                          onChange={e =>
                                            handleUnitChange(unitIndex, e)
                                          }
                                        />
                                      </div>
                                    </form>

                                    <div className="flex justify-center mb-4">
                                      <button
                                        className="btn btn-sm btn-success"
                                        onClick={() => togglePopup(unit.id)}
                                      >
                                        Ajouter un quizz
                                      </button>
                                      <button
                                        className="btn btn-sm btn-primary ms-2"
                                        onClick={() =>
                                          handleAddChapter(unit.id)
                                        }
                                      >
                                        Ajouter une chapitre
                                      </button>
                                    </div>

                                    {unit.lessons.map((lesson, lessonIndex) => (
                                      <div
                                        key={lessonIndex}
                                        className="accordion"
                                        id="accordionUnit"
                                      >
                                        <div className="accordion-item border-top border-300">
                                          <h2
                                            className="accordion-header"
                                            id={`headingUnit-${lessonIndex}`}
                                          >
                                            <button
                                              className="accordion-button"
                                              type="button"
                                              onClick={() =>
                                                setOpenCourse(
                                                  openCourse === lessonIndex + 1
                                                    ? null
                                                    : lessonIndex + 1
                                                )
                                              }
                                            >
                                              <span>
                                                {lesson.type === 'quizz' ? (
                                                  <>
                                                    {lesson.questions.map(
                                                      (
                                                        question,
                                                        questionIndex
                                                      ) => (
                                                        <div
                                                          key={questionIndex}
                                                        >
                                                          <div className="flex justify-between mb-3">
                                                            <span className="text-sm text-gray-500">
                                                              Question{' '}
                                                              {questionIndex +
                                                                1}{' '}
                                                              -{' '}
                                                              {
                                                                question.question
                                                              }
                                                            </span>
                                                            <button
                                                              className="btn btn-sm btn-danger ml-2"
                                                              onClick={() =>
                                                                handleRemoveQuizz(
                                                                  questionIndex,
                                                                  unitIndex,
                                                                  lessonIndex
                                                                )
                                                              }
                                                            >
                                                              <UilTrashAlt
                                                                size={8}
                                                              />
                                                            </button>
                                                            <button
                                                              className="btn btn-sm btn-primary ml-2 ms-2"
                                                              // onClick={() =>
                                                              //   toggleUpdatePopup(
                                                              //     {
                                                              //       id: question.id,
                                                              //       type: question.type,
                                                              //       question:
                                                              //         question.question,
                                                              //       options:
                                                              //         question.options,
                                                              //       correctAnswer:
                                                              //         question.correctAnswer,
                                                              //       correctAnswerIndex:
                                                              //         question.correctAnswerIndex,
                                                              //       correctAnswerIndices:
                                                              //         question.correctAnswerIndices
                                                              //     }
                                                              //   )
                                                              // }
                                                            >
                                                              <UilEditAlt
                                                                size={8}
                                                              />
                                                            </button>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    Chapitre {lessonIndex + 1}:{' '}
                                                    {lesson.name}
                                                    <button
                                                      className="btn btn-sm btn-danger ml-2"
                                                      onClick={() =>
                                                        handleRemoveChapter(
                                                          unitIndex,
                                                          lessonIndex
                                                        )
                                                      }
                                                    >
                                                      <UilTrashAlt size={8} />
                                                    </button>
                                                    <button
                                                      className="btn btn-sm btn-primary ml-2 ms-2"
                                                      onClick={() =>
                                                        handleEditChapter(
                                                          unitIndex,
                                                          lessonIndex
                                                        )
                                                      }
                                                    >
                                                      <UilEditAlt size={8} />
                                                    </button>
                                                  </>
                                                )}
                                              </span>
                                            </button>
                                          </h2>
                                          <Modal
                                            show={showChapterUpdateModal}
                                            onHide={() =>
                                              setShowChapterUpdateModal(false)
                                            }
                                            size="lg"
                                          >
                                            <Modal.Header closeButton>
                                              <Modal.Title>
                                                Mettre à jour ce chapitre
                                              </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                              <form className="row g-3 needs-validation">
                                                <div className="col-md-12">
                                                  <label className="form-label">
                                                    Titre du chapitre
                                                  </label>
                                                  <input
                                                    className="form-control"
                                                    type="text"
                                                    name="name"
                                                    placeholder="Nom du chapitre"
                                                    value={updateChapterName}
                                                    onChange={e =>
                                                      setUpdateChapterName(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className="mb-2">
                                                  <label className="form-label">
                                                    Descriprion de l'aperçu du
                                                    chapitre
                                                  </label>
                                                  <TinymceEditor
                                                    value={
                                                      updateChapterDescription
                                                    }
                                                    onChange={description =>
                                                      handleEditorChangeUpdateDescription(
                                                        description
                                                      )
                                                    }
                                                  />
                                                </div>
                                                {/* {lesson.type !== 'quiz' && (
                                                  <div className="mb-2">
                                                    <label className="form-label">
                                                      Les objectifs du chapitre
                                                    </label>
                                                    <TinymceEditor
                                                      value={newLessonObjective}
                                                      onChange={objective =>
                                                        handleEditorChangeLessonObjective(
                                                          objective
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                )} */}
                                              </form>
                                            </Modal.Body>
                                            <Modal.Footer>
                                              <button
                                                className="btn btn-secondary"
                                                onClick={() =>
                                                  setShowChapterModal(false)
                                                }
                                              >
                                                Annuler
                                              </button>
                                              <button
                                                className="btn btn-primary"
                                                onClick={() =>
                                                  validEditChapter(
                                                    unitIndex,
                                                    lessonIndex
                                                  )
                                                }
                                              >
                                                Mettre à jour
                                              </button>
                                            </Modal.Footer>
                                          </Modal>
                                          <div className="space-y-4"></div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <QuizModal
          show={true}
          handleClose={() => toogleClosePopup()}
          unitId={newUnitId}
        />
      )}

      <Modal show={showUnitModal} onHide={() => setShowUnitModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Créer une nouvelle unité</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label>Nom de l'unité</label>
              <input
                type="text"
                value={newUnitName}
                onChange={e => setnewUnitName(e.target.value)}
                className="form-control"
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowUnitModal(false)}
          >
            Annuler
          </button>
          <button className="btn btn-primary" onClick={() => validAddUnit()}>
            Créer l'unité
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showChapterModal}
        onHide={() => setShowChapterModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Créer une nouvelle chapitre</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3 needs-validation">
            <div className="col-md-12">
              <label className="form-label">Titre du chapitre</label>
              <input
                className="form-control"
                type="text"
                name="name"
                placeholder="Nom du chapitre"
                value={newChapterName}
                onChange={e => setnewChapterName(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label className="form-label">
                Descriprion de l'aperçu du chapitre
              </label>

              <TinymceEditor
                value={newChapterDescription}
                onChange={description =>
                  handleEditorChangeDescription(description)
                }
              />
            </div>
            {/* <div className="mb-2">
              <label className="form-label">Les objectifs du cours </label>
              <TinymceEditor
                value={newChapterObjective}
                onChange={objective => handleEditorChangeObjective(objective)}
              />
            </div> */}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowChapterModal(false)}
          >
            Annuler
          </button>
          <button className="btn btn-primary" onClick={() => validAddChapter()}>
            Créer le chapitre
          </button>
        </Modal.Footer>
      </Modal>

      {showUpdatePopup && (
        <QuizUpdateModal
          show={true}
          handleClose={() => toogleCloseUpdatePopup()}
          onUpdate={handleUpdateQuizz}
          question={quizzToUpdate}
        />
      )}
    </div>
  );
};

export default CreateCourses;
