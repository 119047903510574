import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UilTrashAlt, UilPlus } from '@iconscout/react-unicons';
import { Form, Modal, Button, Row, Col } from 'react-bootstrap';

interface UpdateModalProps {
  show: boolean;
  handleClose: () => void;
  onUpdate: (updatedQuizz: {
    id: number;
    type: string;
    question: string;
    options: string[];
    correctAnswerIndex: number | null;
    correctAnswerIndices: number[];
    correctAnswer: boolean | null;
  }) => void;
  question: {
    id: number;
    type: string;
    question: string;
    options: string[];
    correctAnswerIndex: number | null;
    correctAnswerIndices: number[];
    correctAnswer: boolean | null;
  } | null;
}

const QuizzUpdateModal: React.FC<UpdateModalProps> = ({
  show,
  handleClose,
  onUpdate,
  question
}) => {
  const [questionType, setQuestionType] = useState<string>('');
  const [questionText, setQuestionText] = useState<string>('');
  const [options, setOptions] = useState<string[]>([]);
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState<number | null>(
    null
  );
  const [correctAnswerIndices, setCorrectAnswerIndices] = useState<number[]>(
    []
  );
  const [correctAnswer, setCorrectAnswer] = useState<boolean | null>(null);

  useEffect(() => {
    if (question) {
      setQuestionType(question.type);
      setQuestionText(question.question);
      setOptions(Array.isArray(question.options) ? question.options : []);
      setCorrectAnswerIndex(question.correctAnswerIndex);
      setCorrectAnswerIndices(question.correctAnswerIndices);
      setCorrectAnswer(question.correctAnswer);
    }
  }, [question]);

  const handleUpdate = () => {
    if (question) {
      onUpdate({
        id: question.id,
        type: questionType,
        question: questionText,
        options,
        correctAnswerIndex,
        correctAnswerIndices,
        correctAnswer
      });
      toast.success('Question mise à jour avec succès');
      handleClose();
    }
  };

  const handleOptionChange = (index: number, value: string) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleAddOption = () => {
    setOptions([...options, '']);
  };

  const handleRemoveOption = (index: number) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg" scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Mettre à jour la question</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="questionType">
          <Form.Label>Type de question</Form.Label>
          <Form.Control
            as="select"
            value={questionType}
            onChange={e =>
              setQuestionType(
                e.target.value as
                  | 'singleChoice'
                  | 'multipleChoice'
                  | 'trueFalse'
                  | ''
              )
            }
            required
          >
            <option value="">Sélectionner</option>
            <option value="singleChoice">Choix unique</option>
            <option value="multipleChoice">Choix multiple</option>
            <option value="trueFalse">Vrai/Faux</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="questionText">
          <Form.Label>Question</Form.Label>
          <Form.Control
            type="text"
            value={questionText}
            onChange={e => setQuestionText(e.target.value)}
            required
          />
        </Form.Group>
        {options.map((option, index) => (
          <Form.Group key={index} controlId={`option-${index}`}>
            <Row>
              <Col md={10}>
                <Form.Control
                  type="text"
                  value={option}
                  onChange={e => handleOptionChange(index, e.target.value)}
                  required
                />
              </Col>
              <Col md={2}>
                <Button
                  variant="danger"
                  onClick={() => handleRemoveOption(index)}
                >
                  <UilTrashAlt />
                </Button>
              </Col>
            </Row>
          </Form.Group>
        ))}
        <Button variant="primary" onClick={handleAddOption}>
          <UilPlus /> Ajouter une option
        </Button>
        {questionType === 'singleChoice' && (
          <Form.Group controlId="correctAnswerIndex">
            <Form.Label>Index de la réponse correcte</Form.Label>
            <Form.Control
              type="number"
              value={correctAnswerIndex ?? ''}
              onChange={e => setCorrectAnswerIndex(Number(e.target.value))}
            />
          </Form.Group>
        )}
        {questionType === 'multipleChoice' && (
          <Form.Group controlId="correctAnswerIndices">
            <Form.Label>Indices des réponses correctes</Form.Label>
            <Form.Control
              type="text"
              value={correctAnswerIndices.join(',')}
              onChange={e =>
                setCorrectAnswerIndices(
                  e.target.value.split(',').map(num => parseInt(num.trim(), 10))
                )
              }
            />
          </Form.Group>
        )}
        {questionType === 'trueFalse' && (
          <Form.Group controlId="correctAnswer">
            <Form.Label>Réponse correcte</Form.Label>
            <Form.Control
              as="select"
              value={correctAnswer ? 'true' : 'false'}
              onChange={e => setCorrectAnswer(e.target.value === 'true')}
            >
              <option value="true">Vrai</option>
              <option value="false">Faux</option>
            </Form.Control>
          </Form.Group>
        )}
        <div className="mt-4">
          <Button onClick={handleUpdate} variant="success">
            Mettre à jour la question
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QuizzUpdateModal;
