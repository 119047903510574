import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';

interface UpdateModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: (updatedActivity: {
    id: number;
    name: string;
    description: string;
    country: string;
    address: string;
    imageFile?: File | null;
  }) => void;
  partner: {
    id: number;
    name: string;
    description: string;
    country: string;
    address: string;
  } | null;
}

interface Country {
  id: number;
  libelle: string;
}

const PartnerUpdateModal: React.FC<UpdateModalProps> = ({
  open,
  onClose,
  onUpdate,
  partner
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [country, setCountry] = useState('');
  const [address, setAddress] = useState('');
  const [countries, setCountries] = useState<Country[]>([]);
  const [imageFile, setImageFile] = useState<File | null>(null);

  useEffect(() => {
    if (partner) {
      setName(partner.name);
      setDescription(partner.description);
      setCountry(partner.country);
      setAddress(partner.address);
    }
  }, [partner]);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(`http://localhost:3001/api/v1/country`);
        if (response.ok) {
          const data = await response.json();
          setCountries(data);
        } else {
          console.error(
            'Erreur lors de la récupération des pays',
            response.statusText
          );
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des pays', error);
      }
    };
    fetchCountries();
  }, []);

  const handleUpdate = () => {
    if (partner) {
      onUpdate({
        id: partner.id,
        name,
        description,
        country,
        address,
        imageFile
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 1
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          Mettre à jour l'activité
        </Typography>
        <TextField
          margin="normal"
          label="Nom"
          fullWidth
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <TextField
          margin="normal"
          label="Description"
          fullWidth
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="country-select-label">Pays</InputLabel>
          <Select
            labelId="country-select-label"
            value={country || ''}
            label="Pays"
            onChange={e => setCountry(e.target.value)}
          >
            {countries.map(country => (
              <MenuItem key={country.id} value={country.libelle}>
                {country.libelle}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="normal"
          label="Addresse"
          fullWidth
          value={address}
          onChange={e => setAddress(e.target.value)}
        />
        <Button variant="contained" component="label" sx={{ mt: 2 }}>
          Changer l'image
          <input type="file" hidden onChange={handleImageChange} />
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button
            onClick={onClose}
            sx={{ mr: 2 }}
            variant="contained"
            color="primary"
          >
            Annuler
          </Button>
          <Button onClick={handleUpdate} variant="contained" color="primary">
            Mettre à jour
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PartnerUpdateModal;
