import { ColumnDef } from '@tanstack/react-table';
// import AdvanceTable from 'components/base/AdvanceTable';
// import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
// import Avatar from 'components/base/Avatar';
import { Member } from 'data/members';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ActivityDeleteModal from 'components/modals/ActivityDeleteModal';
import PartnerUpdateModal from 'components/modals/PartnerUpdateModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const membersTablecolumns: ColumnDef<Member>[] = [];
interface Partner {
  id: number;
  name: string;
  description: string;
  country: string;
  address: string;
}
const PartnerTable = () => {
  // Popup de suppression de partenaire
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const handleOpenModal = (item: { id: number; name: string }) => {
    setItemToDelete(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setItemToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (itemToDelete) {
      try {
        const response = await fetch(
          `http://localhost:3005/api/v1/partner/${itemToDelete.id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (response.ok) {
          toast.success('Partenaire supprimer avec succès');
          // Mettre à jour l'état ou effectuer d'autres actions après la suppression
          setPartner(prevPartners =>
            prevPartners.filter(partner => partner.id !== itemToDelete.id)
          );
        } else {
          toast.error('Erreur produit lors de la suppression');
          console.error('Erreur de suppression', response.statusText);
        }
      } catch (error) {
        toast.error('Erreur produit lors de la suppression');
        console.error('Erreur de suppression', error);
      } finally {
        setIsModalOpen(false);
        // window.location.reload();
      }
    }
  };

  // Popup de mise à jour de partenaires
  const [partnerToUpdate, setPartnerToUpdate] = useState<{
    id: number;
    name: string;
    description: string;
    country: string;
    address: string;
  } | null>(null);

  const handleUpdateOpenModal = (partner: {
    id: number;
    name: string;
    description: string;
    country: string;
    address: string;
  }) => {
    setPartnerToUpdate(partner);
    setIsModalOpen(true);
  };

  const handleUpdateCloseModal = () => {
    setIsModalOpen(false);
    setPartnerToUpdate(null);
  };

  const handleUpdatePartner = async (updatedPartner: {
    id: number;
    name: string;
    description: string;
    country: string;
    address: string;
  }) => {
    try {
      const response = await fetch(
        `http://localhost:3005/api/v1/partner/${updatedPartner.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: updatedPartner.name,
            description: updatedPartner.description,
            country: updatedPartner.country,
            address: updatedPartner.address
          })
        }
      );

      if (response.ok) {
        toast.success('Partner updated successfully');
        // Mettre à jour l'état ou effectuer d'autres actions après la mise à jour
        setPartner(prevPartners =>
          prevPartners.map(partner =>
            partner.id === updatedPartner.id ? updatedPartner : partner
          )
        );
      } else {
        toast.error('Error updating partner');
        console.error('Erreur de mise à jour', response.statusText);
      }
    } catch (error) {
      toast.error('Error updating partner');
      console.error('Erreur de mise à jour', error);
    } finally {
      setIsModalOpen(false);
      // window.location.reload();
    }
  };

  const [partners, setPartner] = useState<Partner[]>([]);
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    async function fetchPartner() {
      try {
        const response = await axios.get(
          'http://localhost:3005/api/v1/partner'
        );
        console.log('data :', response.data);
        setPartner(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchPartner();
  }, []);

  return (
    <div
      id="tableExample"
      data-list='{"valueNames":["name","email","age"],"page":5,"pagination":true}'
    >
      <div className="table-responsive">
        <table className="table table-sm fs--1 mb-0">
          <thead>
            <tr>
              <th className="sort border-top ps-3" data-sort="number">
                N°
              </th>
              <th className="sort border-top ps-3" data-sort="libelle">
                name
              </th>
              <th className="sort border-top" data-sort="description">
                Descriptions
              </th>
              <th className="sort border-top" data-sort="domain">
                country
              </th>
              <th className="sort border-top" data-sort="pdf">
                address
              </th>
              <th
                className="sort text-end align-middle pe-0 border-top"
                scope="col"
              >
                ACTIONS
              </th>
            </tr>
          </thead>
          <tbody className="list">
            {partners.map(partner => (
              <tr key={partner.id}>
                <td className="align-middle ps-3 number">{partner.id}</td>
                <td className="align-middle libelle">{partner.name}</td>
                <td className="align-middle description">
                  {showFullDescription
                    ? partner.description
                    : `${partner.description.slice(0, 100)}...`}
                  {partner.description.length > 100 && (
                    <button
                      className="btn btn-link p-0 ms-2"
                      onClick={() =>
                        setShowFullDescription(!showFullDescription)
                      }
                    >
                      {showFullDescription ? 'Voir moins' : 'Voir plus'}
                    </button>
                  )}
                </td>
                <td className="align-middle libelle">{partner.country}</td>
                <td className="align-middle country">{partner.address}</td>
                <td className="align-middle white-space-nowrap text-end pe-0">
                  <div className="font-sans-serif btn-reveal-trigger position-static">
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="me-2"
                      onClick={() =>
                        handleOpenModal({
                          id: partner.id,
                          name: partner.name
                        })
                      }
                    />{' '}
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="me-2"
                      onClick={() =>
                        handleUpdateOpenModal({
                          id: partner.id,
                          name: partner.name,
                          description: partner.description,
                          country: partner.country,
                          address: partner.address
                        })
                      }
                    />{' '}
                  </div>{' '}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {itemToDelete && (
        <ActivityDeleteModal
          open={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleConfirmDelete}
          itemName={itemToDelete.name}
        />
      )}
      {partnerToUpdate && (
        <PartnerUpdateModal
          open={isModalOpen}
          onClose={handleUpdateCloseModal}
          onUpdate={handleUpdatePartner}
          partner={partnerToUpdate}
        />
      )}
    </div>
  );
};

export default PartnerTable;
