import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Icon,
  UilChartPie,
  UilFilesLandscapesAlt
} from '@iconscout/react-unicons';
// import axios from 'axios';
export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  // editIcon?: IconProp | string | string[];
  // deleteIcon?: IconProp | string | string[];
  // addIcon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

// interface Course {
//   id: number;
//   name: string;
// }

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Tableau de bord',
        icon: 'menu',
        path: '/',
        pathName: 'default-dashboard',
        active: true
      }
    ]
  },
  {
    label: 'Ressource',
    icon: UilFilesLandscapesAlt,
    megaMenu: true, // works for navbar top
    active: true,
    pages: [
      {
        name: 'documents',
        icon: 'file',
        path: '/resource/document',
        pathName: 'document',
        active: true
      },
      {
        name: 'offres',
        icon: 'package',
        path: '/resource/offre',
        pathName: 'offre',
        active: true
      },
      {
        name: 'activites',
        icon: 'activity',
        path: '/pages/activites',
        pathName: 'activite-page',
        active: true
      },
      {
        name: 'domaines',
        icon: 'layers',
        path: '/pages/domaines',
        pathName: 'domaine-page',
        active: true
      },
      {
        name: 'equipes',
        icon: 'users',
        path: '/pages/members',
        pathName: 'members-page',
        active: true
      },
      {
        name: 'partenaires',
        icon: 'user',
        path: '/pages/partenaires',
        pathName: 'partenaires-page',
        active: true
      },
      {
        name: 'pays',
        icon: 'columns',
        path: '/pages/pays',
        pathName: 'pays-page',
        active: true
      },
      {
        name: 'Mooc',
        icon: 'file-text',
        active: true,
        path: '/pages/cours'
        // hasNew: true,
      }
    ]
  }
  // {
  //   label: 'MOOC',
  //   active: true,
  //   icon: UilPuzzlePiece,
  //   megaMenu: true, // works for navbar top
  //   pages: [
  //     {
  //       name: 'Mooc',
  //       icon: 'file-text',
  //       active: true,
  //       path: '/pages/cours'
  //       // hasNew: true,
  //     }
  //   ]
  // },
];
