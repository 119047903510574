import { Form, Modal } from 'react-bootstrap';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ReviewModalProps {
  show: boolean;
  handleClose: () => void;
}

const DomainModal = ({ show, handleClose }: ReviewModalProps) => {
  const token = localStorage.bearerToken;
  const headers = { Authorization: `Bearer ${token}` };
  const [liblelle, setLiblelle] = useState('');
  const [description, setDescription] = useState('');
  const [country, setCountry] = useState('');

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'http://localhost:3001/api/v1/domain',
        {
          liblelle,
          description,
          country
        },
        { headers }
      );
      toast.success('Domaine créée avec succès');
      console.log('createData :', response.data);
      handleClose();
      window.location.reload();
    } catch (error) {
      toast.error('Erreur produit de la création');
      console.error(error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="p-4">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <Form.Label className="form-label-header mb-2">Libelle</Form.Label>
            <input
              className="form-control"
              name="libelle"
              type="text"
              placeholder="Libelle du domaine"
              value={liblelle}
              onChange={e => setLiblelle(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <Form.Label className="form-label-header mb-2">Pays</Form.Label>
            <Form.Select onChange={e => setCountry(e.target.value)}>
              <option value="France">France</option>
              <option value="Espagne">Espagne</option>
              <option value="Grèce">Grèce</option>
              <option value="Turquie">Turquie</option>
              <option value="Italie">Italie</option>
              <option value="Chypre">Chypre</option>
            </Form.Select>
          </div>
          <div className="mb-3">
            <h5 className="text-body-highlight mb-3">Description</h5>
            <Form.Control
              value={description}
              onChange={e => setDescription(e.target.value)}
              as="textarea"
              rows={5}
            />
          </div>
          <div className="d-flex flex-between-center">
            <button className="btn-danger" onClick={handleClose}>
              Close
            </button>
            <button type="submit" className="rounded-pill">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default DomainModal;
