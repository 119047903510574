import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/img/logo_blue_tourism.png';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line prettier/prettier
const SignInForm = () => {
  /// Connexion à l'espace administratreur
  // const [email, setEmail] = useState('');
  const [userName, setuserName] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        'https://apis.blue-tourism.eu/api/v1/admin/login',
        {
          userName: userName,
          password: password
        },
        {
          headers: {
            Accept: '*/*'
          }
        }
      );
      // Redirige l'utilisateur vers le tableau de bord
      const tokenResponse = await response.data.tokens;
      const token = tokenResponse.access.token;
      localStorage.setItem('bearerToken', token);
      console.log('Connexion réussie', token);
      toast.success('Connexion réussie');
      navigate('/dashboard');
    } catch (error) {
      toast.error('Erreur de connexion');
      console.error(error);
    }
  };
  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Se connecter</h3>
        <img src={Logo} alt="" />
        {/* <p className="text-body-tertiary">Get access to your account</p> */}
      </div>
      <div className="position-relative">
        <hr className="bg-body-secondary mt-5 mb-4" />
      </div>
      <form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Adresse Email</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="username"
              type="text"
              className="form-icon-input"
              placeholder="Identifiant"
              value={userName}
              onChange={e => setuserName(e.target.value)}
              required
            />
            <FontAwesomeIcon
              icon={faUser}
              className="text-body fs-9 form-icon"
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="password">Mot de passe</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="password"
              type="password"
              className="form-icon-input"
              placeholder="Mot de passe"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
            <FontAwesomeIcon
              icon={faKey}
              className="text-body fs-9 form-icon"
            />
          </div>
        </Form.Group>
        <Row className="flex-between-center mb-7">
          <Col xs="auto">
            <Link to={`/auth/forgot-password`} className="fs-9 fw-semibold">
              Mot de passe oublié?
            </Link>
          </Col>
        </Row>
        <button type="submit" className="w-full mb-3 bg-blue py-2 px-4 rounded">
          Se connecter
        </button>
      </form>
    </>
  );
};

export default SignInForm;
