import { ColumnDef } from '@tanstack/react-table';
// import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
// import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
// import Avatar from 'components/base/Avatar';
import { Member } from 'data/members';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const membersTablecolumns: ColumnDef<Member>[] = [
  {
    accessorKey: 'name',
    header: 'Member',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return (
        <Link to="#!" className=" d-flex align-items-center text-body">
          {/* <Avatar src={avatar} size="m" /> */}
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">{name}</p>
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '200px' },
        className: 'px-1'
      },
      cellProps: { className: 'align-middle white-space-nowrap py-2' }
    }
  },
  {
    accessorKey: 'email',
    header: 'Email',
    cell: ({ row: { original } }) => {
      const { email } = original;
      return (
        <Link to={`mailto:${email}`} className="fw-semibold">
          {email}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '200px' }
      },
      cellProps: { className: 'white-space-nowrap' }
    }
  },
  {
    accessorKey: 'mobile',
    header: 'Mobile Number',
    cell: ({ row: { original } }) => {
      const { mobile } = original;
      return (
        <Link to={`tel:${mobile}`} className="fw-bold text-body-emphasis">
          {mobile}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '200px' },
        className: 'pe-3'
      }
    }
  },
  {
    accessorKey: 'city',
    header: 'City',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-body' }
    }
  },
  {
    accessorKey: 'lastActive',
    header: 'Last Active',
    meta: {
      headerProps: {
        style: { width: '21%', minWidth: '200px' },
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end text-body-tertiary'
      }
    }
  },
  {
    accessorKey: 'joined',
    header: 'Joined',
    meta: {
      headerProps: {
        style: { width: '19%', minWidth: '200px' },
        className: 'text-end pe-0'
      },
      cellProps: {
        className: 'text-body-tertiary text-end'
      }
    }
  }
];

const OffresTable = () => {
  return (
    <div
      id="tableExample"
      data-list='{"valueNames":["name","email","age"],"page":5,"pagination":true}'
    >
      <div className="table-responsive">
        <table className="table table-sm fs--1 mb-0">
          <thead>
            <tr>
              <th className="sort border-top ps-3" data-sort="number">
                N°
              </th>
              <th className="sort border-top ps-3" data-sort="libelle">
                Libellés
              </th>
              <th className="sort border-top" data-sort="description">
                Domaines
              </th>
              <th className="sort border-top" data-sort="description">
                Activités
              </th>
              <th className="sort border-top" data-sort="description">
                Descriptions
              </th>
              <th className="sort border-top" data-sort="age">
                PDF
              </th>
              <th
                className="sort text-end align-middle pe-0 border-top"
                scope="col"
              >
                ACTION
              </th>
            </tr>
          </thead>
          <tbody className="list">
            <tr>
              <td className="align-middle ps-3 number">01</td>
              <td className="align-middle libelle">Blue tourism chypre</td>
              <td className="align-middle domain">Sur l'eau</td>
              <td className="align-middle activite">Sky</td>
              <td className="align-middle description">Offre de fromation </td>
              <td className="align-middle pdf">352Mo</td>
              <td className="align-middle white-space-nowrap text-end pe-0">
                <div className="font-sans-serif btn-reveal-trigger position-static">
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                </div>{' '}
              </td>
            </tr>
            <tr>
              <td className="align-middle ps-3 number">01</td>
              <td className="align-middle libelle">Blue tourism chypre</td>
              <td className="align-middle domain">Sur l'eau</td>
              <td className="align-middle activite">Sky</td>
              <td className="align-middle description">Offre de fromation </td>
              <td className="align-middle pdf">352Mo</td>
              <td className="align-middle white-space-nowrap text-end pe-0">
                <div className="font-sans-serif btn-reveal-trigger position-static">
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                </div>{' '}
              </td>
            </tr>
            <tr>
              <td className="align-middle ps-3 number">02</td>
              <td className="align-middle libelle">Blue tourism chypre</td>
              <td className="align-middle domain">Sur l'eau</td>
              <td className="align-middle activite">Sky</td>
              <td className="align-middle description">Offre de fromation </td>
              <td className="align-middle pdf">352Mo</td>
              <td className="align-middle white-space-nowrap text-end pe-0">
                <div className="font-sans-serif btn-reveal-trigger position-static">
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                </div>{' '}
              </td>
            </tr>
            <tr>
              <td className="align-middle ps-3 number">03</td>
              <td className="align-middle libelle">Blue tourism chypre</td>
              <td className="align-middle domain">Sur l'eau</td>
              <td className="align-middle activite">Sky</td>
              <td className="align-middle description">Offre de fromation </td>
              <td className="align-middle pdf">352Mo</td>
              <td className="align-middle white-space-nowrap text-end pe-0">
                <div className="font-sans-serif btn-reveal-trigger position-static">
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                </div>{' '}
              </td>
            </tr>
            <tr>
              <td className="align-middle ps-3 number">04</td>
              <td className="align-middle libelle">Blue tourism chypre</td>
              <td className="align-middle domain">Sur l'eau</td>
              <td className="align-middle activite">Sky</td>
              <td className="align-middle description">Offre de fromation </td>
              <td className="align-middle pdf">352Mo</td>
              <td className="align-middle white-space-nowrap text-end pe-0">
                <div className="font-sans-serif btn-reveal-trigger position-static">
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                </div>{' '}
              </td>
            </tr>
            <tr>
              <td className="align-middle ps-3 number">05</td>
              <td className="align-middle libelle">Blue tourism chypre</td>
              <td className="align-middle domain">Sur l'eau</td>
              <td className="align-middle activite">Sky</td>
              <td className="align-middle description">Offre de fromation </td>
              <td className="align-middle pdf">352Mo</td>
              <td className="align-middle white-space-nowrap text-end pe-0">
                <div className="font-sans-serif btn-reveal-trigger position-static">
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                </div>{' '}
              </td>
            </tr>
            <tr>
              <td className="align-middle ps-3 number">06</td>
              <td className="align-middle libelle">Blue tourism chypre</td>
              <td className="align-middle domain">Sur l'eau</td>
              <td className="align-middle activite">Sky</td>
              <td className="align-middle description">Offre de fromation </td>
              <td className="align-middle pdf">352Mo</td>
              <td className="align-middle white-space-nowrap text-end pe-0">
                <div className="font-sans-serif btn-reveal-trigger position-static">
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                </div>{' '}
              </td>
            </tr>
            <tr>
              <td className="align-middle ps-3 number">07</td>
              <td className="align-middle libelle">Blue tourism chypre</td>
              <td className="align-middle domain">Sur l'eau</td>
              <td className="align-middle activite">Sky</td>
              <td className="align-middle description">Offre de fromation </td>
              <td className="align-middle pdf">352Mo</td>
              <td className="align-middle white-space-nowrap text-end pe-0">
                <div className="font-sans-serif btn-reveal-trigger position-static">
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                </div>{' '}
              </td>
            </tr>
            <tr>
              <td className="align-middle ps-3 number">08</td>
              <td className="align-middle libelle">Blue tourism chypre</td>
              <td className="align-middle domain">Sur l'eau</td>
              <td className="align-middle activite">Sky</td>
              <td className="align-middle description">Offre de fromation </td>
              <td className="align-middle pdf">352Mo</td>
              <td className="align-middle white-space-nowrap text-end pe-0">
                <div className="font-sans-serif btn-reveal-trigger position-static">
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                </div>{' '}
              </td>
            </tr>
            <tr>
              <td className="align-middle ps-3 number">09</td>
              <td className="align-middle libelle">Blue tourism chypre</td>
              <td className="align-middle domain">Sur l'eau</td>
              <td className="align-middle activite">Sky</td>
              <td className="align-middle description">Offre de fromation </td>
              <td className="align-middle pdf">352Mo</td>
              <td className="align-middle white-space-nowrap text-end pe-0">
                <div className="font-sans-serif btn-reveal-trigger position-static">
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                </div>{' '}
              </td>
            </tr>
            <tr>
              <td className="align-middle ps-3 number">10</td>
              <td className="align-middle libelle">Blue tourism chypre</td>
              <td className="align-middle domain">Sur l'eau</td>
              <td className="align-middle activite">Sky</td>
              <td className="align-middle description">Offre de fromation </td>
              <td className="align-middle pdf">352Mo</td>
              <td className="align-middle white-space-nowrap text-end pe-0">
                <div className="font-sans-serif btn-reveal-trigger position-static">
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                </div>{' '}
              </td>
            </tr>
            <tr>
              <td className="align-middle ps-3 number">11</td>
              <td className="align-middle libelle">Blue tourism chypre</td>
              <td className="align-middle domain">Sur l'eau</td>
              <td className="align-middle activite">Sky</td>
              <td className="align-middle description">Offre de fromation </td>
              <td className="align-middle pdf">352Mo</td>
              <td className="align-middle white-space-nowrap text-end pe-0">
                <div className="font-sans-serif btn-reveal-trigger position-static">
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                </div>{' '}
              </td>
            </tr>
            <tr>
              <td className="align-middle ps-3 number">12</td>
              <td className="align-middle libelle">Blue tourism chypre</td>
              <td className="align-middle domain">Sur l'eau</td>
              <td className="align-middle activite">Sky</td>
              <td className="align-middle description">Offre de fromation </td>
              <td className="align-middle pdf">352Mo</td>
              <td className="align-middle white-space-nowrap text-end pe-0">
                <div className="font-sans-serif btn-reveal-trigger position-static">
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OffresTable;
