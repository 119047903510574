import { ColumnDef } from '@tanstack/react-table';
import { Member } from 'data/members';
import {
  faTrashAlt,
  faEdit,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import CoursDeleteModal from 'components/modals/CoursDeleteModal';
import CoursUpdateModal from 'components/modals/CoursUpdateModal';
import ReactPaginate from 'react-paginate';

export const membersTablecolumns: ColumnDef<Member>[] = [];

interface Courses {
  objective: string;
  id: number;
  name: string;
  duration: string;
  description: string;
}

const CoursTable = () => {
  const navigate = useNavigate();
  const token = localStorage.bearerToken;
  const headers = { Authorization: `Bearer ${token}` };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [CoursToUpdate, setCoursToUpdate] = useState<{
    id: number;
    name: string;
    description: string;
    objective: string;
  } | null>(null);
  const [courses, setCourses] = useState<Courses[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(5);

  useEffect(() => {
    async function fetchCourses() {
      try {
        const response = await axios.get(
          'https://apis.blue-tourism.eu/api/v1/course',
          { headers }
        );
        setCourses(response.data.courses);
      } catch (error) {
        console.error(error);
      }
    }

    fetchCourses();
  }, [headers]);

  const handleOpenModal = (item: { id: number; name: string }) => {
    setItemToDelete(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setItemToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (itemToDelete) {
      try {
        const response = await fetch(
          `https://apis.blue-tourism.eu/api/v1/course/${itemToDelete.id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          }
        );

        if (response.ok) {
          toast.success('Cours supprimé avec succès');
          setCourses(courses.filter(cours => cours.id !== itemToDelete.id));
        } else {
          toast.error('Erreur produite lors de la suppression');
        }
      } catch (error) {
        toast.error('Erreur produite lors de la suppression');
      } finally {
        setIsModalOpen(false);
      }
    }
  };

  const handleUpdateOpenModal = (cours: {
    id: number;
    name: string;
    description: string;
    objective: string;
  }) => {
    setCoursToUpdate(cours);
    setIsModalOpen(true);
  };

  const handleUpdateCloseModal = () => {
    setIsModalOpen(false);
    setCoursToUpdate(null);
  };

  const handleUpdateCours = async (updatedCours: {
    id: number;
    name: string;
    description: string;
    objective: string;
  }) => {
    try {
      const response = await fetch(
        `https://apis.blue-tourism.eu/api/v1/course/${updatedCours.id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            name: updatedCours.name,
            description: updatedCours.description,
            objective: updatedCours.objective
          })
        }
      );

      if (response.ok) {
        toast.success('Cours mis à jour avec succès');
      } else {
        toast.error('Erreur de mise à jour');
      }
    } catch (error) {
      toast.error('Erreur de mise à jour');
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleShowCours = (item: { id: number }) => {
    navigate(`/resource/courses/${item.id}`);
  };

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = courses.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(courses.length / itemsPerPage);

  return (
    <div
      id="tableExample"
      data-list='{"valueNames":["name","email","age"],"page":10,"pagination":true}'
    >
      <div className="table-responsive">
        <table className="table table-sm fs--1 mb-0">
          <thead>
            <tr>
              <th className="sort border-top ps-3" data-sort="number">
                N°
              </th>
              <th className="sort border-top ps-3" data-sort="libelle">
                Titres
              </th>
              <th className="sort border-top" data-sort="description">
                Durées
              </th>
              <th
                className="sort text-end align-middle pe-0 border-top"
                scope="col"
              >
                ACTIONS
              </th>
            </tr>
          </thead>
          <tbody className="list">
            {currentPageData.map((cours, index) => (
              <tr key={cours.id}>
                <td className="align-middle ps-3 number">
                  {index + 1 + offset}
                </td>
                <td className="align-middle libelle">{cours.name}</td>
                <td className="align-middle country">{cours.duration}</td>
                <td className="align-middle white-space-nowrap text-end pe-0">
                  <div className="font-sans-serif btn-reveal-trigger position-static">
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      className="me-2"
                      onClick={() => handleShowCours({ id: cours.id })}
                    />
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="me-2"
                      onClick={() =>
                        handleUpdateOpenModal({
                          id: cours.id,
                          name: cours.name,
                          description: cours.description,
                          objective: cours.objective
                        })
                      }
                    />
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="me-2"
                      onClick={() =>
                        handleOpenModal({ id: cours.id, name: cours.name })
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={10}
        onPageChange={handlePageClick}
        containerClassName={'pagination flex justify-center mt-4'}
        pageClassName={'mx-1'}
        pageLinkClassName={
          'border border-gray-300 rounded px-3 py-1 text-gray-700 hover:bg-gray-200'
        }
        previousClassName={'mx-1'}
        previousLinkClassName={
          'border border-gray-300 rounded px-3 py-1 text-gray-700 hover:bg-gray-200'
        }
        nextClassName={'mx-1'}
        nextLinkClassName={
          'border border-gray-300 rounded px-3 py-1 text-gray-700 hover:bg-gray-200'
        }
        activeClassName={'bg-blue-500 text-white'}
      />
      {itemToDelete && (
        <CoursDeleteModal
          open={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleConfirmDelete}
          itemName={itemToDelete.name}
        />
      )}
      {CoursToUpdate && (
        <CoursUpdateModal
          open={isModalOpen}
          onClose={handleUpdateCloseModal}
          onUpdate={handleUpdateCours}
          cours={CoursToUpdate}
        />
      )}
    </div>
  );
};

export default CoursTable;
