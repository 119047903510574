import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent
} from '@mui/material';

interface UpdateModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: (updatedDocument: {
    id: number;
    name: string;
    description: string;
    typeContent: string;
    countryId: number;
    departmentId: number;
    domainId: number;
    imageFile?: File | null;
  }) => void;
  document: {
    id: number;
    name: string;
    description: string;
    typeContent: string;
    countryId: number;
    departmentId: number;
    domainId: number;
  } | null;
}

interface Domain {
  id: number;
  liblelle: string;
}

interface Department {
  id: number;
  libelle: string;
}

interface Country {
  id: number;
  libelle: string;
}
const DocumentUpdateModal: React.FC<UpdateModalProps> = ({
  open,
  onClose,
  onUpdate,
  document
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [typeContent, setTypeContent] = useState('');
  const [domainId, setDomainId] = useState<number | null>(null);
  const [departmentId, setDepartmentId] = useState<number | null>(null);
  const [countryId, setCountryId] = useState<number | null>(null);

  const [domains, setDomains] = useState<Domain[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [imageFile, setImageFile] = useState<File | null>(null);

  useEffect(() => {
    if (document) {
      setName(document.name);
      setDescription(document.description);
      setTypeContent(document.typeContent);
      setDomainId(document.domainId);
      setDepartmentId(document.departmentId);
      setCountryId(document.countryId);
    }
  }, [document]);

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response = await fetch(`http://localhost:3002/api/v1/domain`);
        if (response.ok) {
          const data = await response.json();
          setDomains(data);
        } else {
          console.error(
            'Erreur lors de la récupération des domaines',
            response.statusText
          );
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des domaines', error);
      }
    };

    const fetchCountries = async () => {
      try {
        const response = await fetch(`http://localhost:3001/api/v1/country`);
        if (response.ok) {
          const data = await response.json();
          setCountries(data);
        } else {
          console.error(
            'Erreur lors de la récupération des départements',
            response.statusText
          );
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des départements', error);
      }
    };

    fetchDomains();
    fetchCountries();
  }, []);

  const fetchDepartments = async (countryId: number) => {
    try {
      const response = await fetch(
        `http://localhost:3001/api/v1/departements/country/${countryId}`
      );
      if (response.ok) {
        const data = await response.json();
        setDepartments(data);
      } else {
        console.error(
          'Erreur lors de la récupération des départements',
          response.statusText
        );
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des départements', error);
    }
  };

  const handleCountryChange = (event: SelectChangeEvent<number>) => {
    const selectedCountryId = Number(event.target.value);
    setCountryId(selectedCountryId);
    fetchDepartments(selectedCountryId);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageFile(event.target.files[0]);
    }
  };

  const handleUpdate = () => {
    if (
      document &&
      domainId !== null &&
      departmentId !== null &&
      countryId !== null
    ) {
      onUpdate({
        id: document.id,
        name,
        description,
        typeContent,
        domainId: document.domainId,
        departmentId: document.departmentId,
        countryId: document.countryId,
        imageFile
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 1
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          Mettre à jour un document
        </Typography>
        <TextField
          margin="normal"
          label="Titre"
          fullWidth
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <TextField
          margin="normal"
          label="Description"
          fullWidth
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="domain-select-label">Domaine</InputLabel>
          <Select
            labelId="domain-select-label"
            value={domainId || ''}
            onChange={e => setDomainId(e.target.value as number)}
            label="Domaine"
          >
            {domains.map(domain => (
              <MenuItem key={domain.id} value={domain.id}>
                {domain.liblelle}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="country-select-label">Pays</InputLabel>
          <Select
            labelId="country-select-label"
            value={countryId || ''}
            label="Pays"
            onChange={handleCountryChange}
          >
            {countries.map(country => (
              <MenuItem key={country.id} value={country.id}>
                {country.libelle}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="department-select-label">Département</InputLabel>
          <Select
            labelId="department-select-label"
            value={departmentId || ''}
            onChange={e => setDepartmentId(e.target.value as number)}
            label="Département"
          >
            {departments.map(department => (
              <MenuItem key={department.id} value={department.id}>
                {department.libelle}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" component="label" sx={{ mt: 2 }}>
          Changer l'image
          <input type="file" hidden onChange={handleImageChange} />
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button
            onClick={onClose}
            sx={{ mr: 2 }}
            variant="contained"
            color="primary"
          >
            Annuler
          </Button>
          <Button onClick={handleUpdate} variant="contained" color="primary">
            Mettre à jour
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DocumentUpdateModal;
