import TinymceEditor from 'components/base/TinymceEditor';
// import { UilPlus } from '@iconscout/react-unicons';
// import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import axios from 'axios'; // Add axios for making HTTP requests
import { toast } from 'react-toastify';
// import DatePicker from 'components/base/DatePicker';
// import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface Course {
  id: number;
  name: string;
  duration: string;
  description: string;
  objective: string;
}

const CreateCourses = () => {
  const [course, setCourse] = useState<Course>({
    id: 0,
    name: '',
    duration: '',
    description: '',
    objective: ''
  }); // Store course data

  const token = localStorage.bearerToken;

  const headers = {
    Authorization: `Bearer ${token}`
  };
  const navigate = useNavigate();

  const handleCreateCourse = () => {
    // Create a new course using the API
    axios
      .post(
        'http://apis.blue-tourism.eu/api/v1/course',
        {
          name: course.name,
          duration: course.duration,
          description: course.description,
          objective: course.objective
        },
        { headers }
      )
      .then(response => {
        console.log('created:', response.data);
        toast.success('Création effectuée avec succès');
        const courseId = response.data.id;
        navigate(`/resource/courses/${courseId}`);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    if (event.target instanceof HTMLSelectElement) {
      setCourse(prevCourse => ({
        ...prevCourse
      }));
    } else {
      setCourse(prevCourse => ({ ...prevCourse, [name]: value }));
    }
  };

  const handleEditorChange = (content: string) => {
    setCourse({ ...course, description: content });
  };

  const handleEditorChangeObjective = (objective: string) => {
    setCourse({ ...course, objective: objective });
  };

  return (
    <div>
      <form className="row g-3 needs-validation" noValidate>
        <div className="col-md-6">
          <label className="form-label" htmlFor="validationCustom01">
            Nom du cours
          </label>
          <input
            className="form-control"
            id="validationCustom01"
            type="text"
            name="name"
            value={course.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="col-md-6">
          <label className="form-label" htmlFor="validationCustom03">
            Durée du cours
          </label>
          <input
            className="form-control"
            id="validationCustom03"
            type="text"
            name="duration"
            value={course.duration}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="validationCustom05">
            La description du cours
          </label>
          <TinymceEditor
            value={course.description}
            onChange={handleEditorChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="validationCustom05">
            Les objectifs du cours
          </label>
          <TinymceEditor
            value={course.objective}
            onChange={handleEditorChangeObjective}
          />
        </div>
      </form>
      <div className="col-12 d-flex justify-content-end">
        <button
          className="btn btn-primary float-left"
          onClick={handleCreateCourse}
        >
          Valider
        </button>
      </div>
    </div>
  );
};

export default CreateCourses;
