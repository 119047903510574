import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWaveSquare,
  faBuildingCircleArrowRight,
  faFile,
  faLandmarkDome,
  faPlay
} from '@fortawesome/free-solid-svg-icons';

const Ecommerce = () => {
  return (
    <>
      <div className="row g-4">
        <h2>Tableau de Bord</h2>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <div className="card text-white dark__text-100 bg-secondary">
            <div className="card-body">
              <FontAwesomeIcon icon={faLandmarkDome} className="me-2" />
              <h4 className="card-title text-white dark__text-100">Domaines</h4>
              <h1 className="card-title text-white dark__text-100">17</h1>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <div className="card text-white dark__text-100 bg-secondary">
            <div className="card-body">
              <FontAwesomeIcon
                icon={faWaveSquare}
                className="me-2 w-full h-full"
              />
              <h4 className="card-title text-white dark__text-100">
                Activités
              </h4>
              <h1 className="card-title text-white dark__text-100">17</h1>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <div className="card text-white dark__text-100 bg-secondary">
            <div className="card-body">
              <FontAwesomeIcon
                icon={faBuildingCircleArrowRight}
                className="me-2"
              />
              <h4 className="card-title text-white dark__text-100">
                Offres d'emplois
              </h4>
              <h1 className="card-title text-white dark__text-100">17</h1>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <div className="card text-white dark__text-100 bg-secondary">
            <div className="card-body">
              <FontAwesomeIcon icon={faFile} className="me-2" />
              <h4 className="card-title text-white dark__text-100">
                Documents
              </h4>
              <h1 className="card-title text-white dark__text-100">17</h1>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <div className="card text-white dark__text-100 bg-secondary">
            <div className="card-body">
              <FontAwesomeIcon icon={faPlay} className="me-2" />
              <h4 className="card-title text-white dark__text-100">
                E-learning
              </h4>
              <h1 className="card-title text-white dark__text-100">17</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ecommerce;
