import React, { useState, useEffect } from 'react';
import TinymceEditor from 'components/base/TinymceEditor';
import { Form, Modal, Button } from 'react-bootstrap';

interface UpdateModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: (updatedCours: {
    id: number;
    name: string;
    description: string;
    objective: string;
  }) => void;
  cours: {
    id: number;
    name: string;
    description: string;
    objective: string;
  } | null;
}

const CoursUpdateModal: React.FC<UpdateModalProps> = ({
  open,
  onClose,
  onUpdate,
  cours
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [objective, setObjective] = useState('');

  useEffect(() => {
    if (cours) {
      setName(cours.name);
      setDescription(cours.description);
      setObjective(cours.objective);
    }
  }, [cours]);

  const handleUpdate = () => {
    if (cours) {
      onUpdate({
        id: cours.id,
        name,
        description,
        objective
      });
    }
  };
  const handleEditorChange = (description: string) => {
    setDescription(description);
  };

  const handleEditorChangeObjective = (objective: string) => {
    setObjective(objective);
  };
  return (
    <Modal show={open} onHide={onClose} centered size="lg" scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Mettre à jour un cours</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Nom du cours</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Description</Form.Label>
            <TinymceEditor value={description} onChange={handleEditorChange} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>L'objectifs du cours</Form.Label>
            <TinymceEditor
              value={objective}
              onChange={handleEditorChangeObjective}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Fermer
        </Button>
        <Button variant="primary" onClick={handleUpdate}>
          Mettre à jour
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CoursUpdateModal;
