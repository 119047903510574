import { Form, Modal } from 'react-bootstrap';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ReviewModalProps {
  show: boolean;
  handleClose: () => void;
}

const PartnerModal = ({ show, handleClose }: ReviewModalProps) => {
  /// Création d'un partenaire
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [country, setCountry] = useState('');
  const [address, setAddress] = useState('');
  const [logoUrl, setFile] = useState<File | ''>('');

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('country', country);
    formData.append('address', address);
    formData.append('logoUrl', logoUrl);

    try {
      const response = await axios.post(
        'http://localhost:3005/api/v1/partner',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      toast.success('Partner added successfully');
      console.log('createData :', response.data);
      handleClose();
      window.location.reload();
    } catch (error) {
      toast.error('Error adding partner');
      console.error("Erreur d'ajout", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="p-4">
        <form
          action="/partner"
          method="post"
          encType="multipart/form-data"
          onSubmit={handleSubmit}
        >
          <div className="mb-3">
            <Form.Label className="form-label-header mb-2">Nom</Form.Label>
            <input
              className="form-control"
              name="name"
              value={name}
              type="text"
              placeholder="Le nom du partenaire"
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <h5 className="text-body-highlight mb-3">Description</h5>
            <Form.Control
              as="textarea"
              onChange={e => setDescription(e.target.value)}
              value={description}
              rows={5}
            />
          </div>
          <div className="mb-3">
            <Form.Label className="form-label-header mb-2">Addresse</Form.Label>
            <input
              className="form-control"
              name="addresse"
              value={address}
              type="text"
              placeholder="L'addresse du partenaire"
              onChange={e => setAddress(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <Form.Label className="form-label-header mb-2">Pays</Form.Label>
            <Form.Select onChange={e => setCountry(e.target.value)}>
              <option value="France">France</option>
              <option value="Espagne">Espagne</option>
              <option value="Grèce">Grèce</option>
              <option value="Turquie">Turquie</option>
              <option value="Italie">Italie</option>
              <option value="Chypre">Chypre</option>
            </Form.Select>
          </div>
          <div className="mb-3">
            <Form.Label className="form-label-header mb-2">
              Ajouter l'image
            </Form.Label>
            <input
              className="form-control"
              name="logoUrl"
              type="file"
              accept="image/*"
              onChange={e => {
                const files = e.target.files;
                if (files && files.length > 0) {
                  setFile(files[0]);
                }
              }}
            />
          </div>
          <div className="d-flex flex-between-center">
            <button className="btn-danger" onClick={handleClose}>
              Close
            </button>
            <button type="submit" className="rounded-pill">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default PartnerModal;
