import { Form, Modal, Button, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UilTrashAlt, UilPlus } from '@iconscout/react-unicons';

interface QuizModalProps {
  show: boolean;
  handleClose: () => void;
  unitId: number | null;
}

interface Question {
  type: 'singleChoice' | 'multipleChoice' | 'trueFalse' | '';
  question: string;
  options: string[];
  correctAnswerIndex: number | null;
  correctAnswerIndices: number[];
  correctAnswer: boolean | null;
}

const QuizModal = ({ show, handleClose, unitId }: QuizModalProps) => {
  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const [questions, setQuestions] = useState<Question[]>([
    {
      type: '',
      question: '',
      options: [''],
      correctAnswerIndex: null,
      correctAnswerIndices: [],
      correctAnswer: null
    }
  ]);

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        type: '',
        question: '',
        options: [''],
        correctAnswerIndex: null,
        correctAnswerIndices: [],
        correctAnswer: null
      }
    ]);
  };

  const handleRemoveQuestion = (index: number) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const handleQuestionChange = (
    index: number,
    field: keyof Question,
    value: string | string[] | boolean
  ) => {
    const newQuestions = [...questions];
    newQuestions[index] = { ...newQuestions[index], [field]: value };
    setQuestions(newQuestions);
  };

  const handleOptionChange = (
    questionIndex: number,
    optionIndex: number,
    value: string
  ) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(newQuestions);
  };

  const handleAddOption = (index: number) => {
    const newQuestions = [...questions];
    newQuestions[index].options.push('');
    setQuestions(newQuestions);
  };

  const handleCorrectAnswerChange = (
    questionIndex: number,
    optionIndex: number,
    type: string,
    isChecked: boolean
  ) => {
    const newQuestions = [...questions];
    if (type === 'singleChoice') {
      newQuestions[questionIndex].correctAnswerIndex = optionIndex;
    } else if (type === 'multipleChoice') {
      if (isChecked) {
        newQuestions[questionIndex].correctAnswerIndices = [
          ...newQuestions[questionIndex].correctAnswerIndices,
          optionIndex
        ];
      } else {
        newQuestions[questionIndex].correctAnswerIndices = newQuestions[
          questionIndex
        ].correctAnswerIndices.filter(index => index !== optionIndex);
      }
    }
    setQuestions(newQuestions);
  };

  const token = localStorage.bearerToken;
  // const unitId = localStorage.unitId;

  const headers = {
    Authorization: `Bearer ${token}`
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const cleanedQuestions = questions.map(question => {
      if (question.type === 'singleChoice') {
        const { correctAnswerIndices, correctAnswer, ...rest } = question;
        // Utilisez les variables correctAnswerIndices et correctAnswer
        console.log('Removed correctAnswerIndices:', correctAnswerIndices);
        console.log('Removed correctAnswer:', correctAnswer);
        return rest;
      } else if (question.type === 'multipleChoice') {
        const { correctAnswerIndex, correctAnswer, ...rest } = question;
        // Utilisez les variables correctAnswerIndex et correctAnswer
        console.log('Removed correctAnswerIndex:', correctAnswerIndex);
        console.log('Removed correctAnswer:', correctAnswer);
        return rest;
      } else if (question.type === 'trueFalse') {
        const { correctAnswerIndex, correctAnswerIndices, ...rest } = question;
        // Utilisez les variables correctAnswerIndex et correctAnswerIndices
        console.log('Removed correctAnswerIndex:', correctAnswerIndex);
        console.log('Removed correctAnswerIndices:', correctAnswerIndices);
        return rest;
      }
      return question;
    });

    const newQuizz = {
      name,
      content,
      sectionId: unitId,
      questions: cleanedQuestions
    };

    try {
      const response = await axios.post(
        'https://apis.blue-tourism.eu/api/v1/quizz',
        newQuizz,
        { headers }
      );
      toast.success('Quiz créé avec succès');
      console.log('createData :', response.data);
      handleClose();
      window.location.reload();
    } catch (error) {
      // console.log(JSON.stringify(newQuizz));
      toast.error('Erreur produit de la création');
      console.error(error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg" scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Créer un Quiz</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="quizName">
            <Form.Label>Nom du quiz</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="quizContent">
            <Form.Label>Contenu</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={content}
              onChange={e => setContent(e.target.value)}
              required
            />
          </Form.Group>
          {questions.map((question, index) => (
            <div key={index} className="mb-4">
              <Row className="align-items-center">
                <Col md={10}>
                  <Form.Group controlId={`questionType${index}`}>
                    <Form.Label>Type de question</Form.Label>
                    <Form.Control
                      as="select"
                      value={question.type}
                      onChange={e =>
                        handleQuestionChange(index, 'type', e.target.value)
                      }
                      required
                    >
                      <option value="">Choisir le type</option>
                      <option value="singleChoice">Choix unique</option>
                      <option value="multipleChoice">Choix multiple</option>
                      <option value="trueFalse">Vrai ou faux</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId={`questionText${index}`}>
                    <Form.Label>Question</Form.Label>
                    <Form.Control
                      type="text"
                      value={question.question}
                      onChange={e =>
                        handleQuestionChange(index, 'question', e.target.value)
                      }
                      required
                    />
                  </Form.Group>
                  {question.options.map((option, optionIndex) => (
                    <div
                      key={optionIndex}
                      className="mb-2 d-flex align-items-center"
                    >
                      <Form.Group
                        controlId={`option${index}-${optionIndex}`}
                        className="w-75"
                      >
                        <Form.Label>Option {optionIndex + 1}</Form.Label>
                        <Form.Control
                          type="text"
                          value={option}
                          onChange={e =>
                            handleOptionChange(
                              index,
                              optionIndex,
                              e.target.value
                            )
                          }
                          required
                        />
                      </Form.Group>
                      {question.type === 'singleChoice' && (
                        <Form.Check
                          type="radio"
                          name={`correctAnswer${index}`}
                          className="ml-2"
                          checked={question.correctAnswerIndex === optionIndex}
                          onChange={() =>
                            handleCorrectAnswerChange(
                              index,
                              optionIndex,
                              question.type,
                              true
                            )
                          }
                          label="Choisir comme réponse correcte"
                        />
                      )}
                      {question.type === 'multipleChoice' && (
                        <Form.Check
                          type="checkbox"
                          className="ml-2"
                          checked={question.correctAnswerIndices.includes(
                            optionIndex
                          )}
                          onChange={e =>
                            handleCorrectAnswerChange(
                              index,
                              optionIndex,
                              question.type,
                              e.target.checked
                            )
                          }
                          label="Choisir comme réponse correcte"
                        />
                      )}
                    </div>
                  ))}
                  {question.type !== 'trueFalse' && (
                    <Button
                      variant="secondary"
                      size="sm"
                      className="mb-2"
                      onClick={() => handleAddOption(index)}
                    >
                      <UilPlus /> Ajouter une option
                    </Button>
                  )}
                  {question.type === 'trueFalse' && (
                    <Form.Group controlId={`correctAnswer${index}`}>
                      <Form.Label>Réponse correcte</Form.Label>
                      <Form.Control
                        as="select"
                        value={question.correctAnswer ? 'true' : 'false'}
                        onChange={e =>
                          handleQuestionChange(
                            index,
                            'correctAnswer',
                            e.target.value === 'true'
                          )
                        }
                        required
                      >
                        <option value="true">Vrai</option>
                        <option value="false">Faux</option>
                      </Form.Control>
                    </Form.Group>
                  )}
                </Col>
                <Col md={2} className="d-flex justify-content-end">
                  <Button
                    variant="danger"
                    size="sm"
                    className="mb-2"
                    onClick={() => handleRemoveQuestion(index)}
                  >
                    <UilTrashAlt />
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <Button
            variant="primary"
            size="sm"
            className="mb-4"
            onClick={handleAddQuestion}
          >
            <UilPlus /> Ajouter une question
          </Button>
          <div className="mt-4">
            <Button type="submit" variant="success" size="sm">
              Créer le quiz
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default QuizModal;
